import { createTask, fetchTasks, updateTask } from '@/api/basecamp.api';
import type { IFetchTaskParams, ITask, ITaskResponse } from '@condo/domain';

export interface ITasksStore {
    tasks: ITask[];
    totalTasks: number;
    openTasks: number;
}

function getInitialState(): ITasksStore {
    return {
        // current number of tasks
        tasks: [],
        // total number of tasks, excluding pagination
        totalTasks: 0,
        openTasks: 0,
    };
}

const state = getInitialState();

const getters = {
    tasks(state: ITasksStore): ITask[] {
        return state.tasks;
    },
    totalTasks(state: ITasksStore): number {
        return state.totalTasks;
    },
    openTasks(state: ITasksStore): number {
        return state.openTasks;
    },
};

const actions = {
    async createTask({ commit }, insertableTask: Partial<ITask>) {
        return createTask(insertableTask).then((task: ITask) => {
            commit('addTask', task);
            return task;
        });
    },
    async updateTask({ commit }, updateadableTask: ITask) {
        return updateTask(updateadableTask).then((task: ITask) => {
            commit('updateTask', task);
            return task;
        });
    },
    async fetchTasks({ commit }, params?: IFetchTaskParams) {
        return fetchTasks(params).then((taskResponse: ITaskResponse) => {
            commit('setTasks', taskResponse);
            return taskResponse.tasks;
        });
    },
    clearTasks({ commit }) {
        commit('clearTasks');
    },
};
const mutations = {
    setTasks(state: ITasksStore, taskResponse: ITaskResponse) {
        state.tasks = taskResponse.tasks;
        state.totalTasks = taskResponse.count;
        state.openTasks = taskResponse.openCount;
    },
    addTask(state: ITasksStore, task: ITask) {
        state.tasks.unshift(task);
    },
    updateTask(state: ITasksStore, task: ITask) {
        state.tasks = state.tasks.map(t => (t.taskId === task.taskId ? task : t));
    },
    clearTasks(state: ITasksStore) {
        state.tasks = [];
    },
};

export const tasksStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
