import type { IRegion, IRegionStatsResponse, ISellingRegion } from '@condo/domain';
import { omit } from 'lodash-es';
import { basecamp } from './api-client';

export const getRegions = async (): Promise<IRegion[]> => basecamp.get('/region').then(response => response.data);

export const updateRegion = async (region: IRegion): Promise<IRegion[]> =>
    basecamp.patch(`/region/${region.regionId}`, omit(region, 'regionId', 'shortName', 'regionName')).then(response => response.data);

export const createRegion = async (region: IRegion): Promise<IRegion[]> => basecamp.post('/region', region).then(response => response.data);

export const getRegion = async (regionId: number): Promise<IRegion> => basecamp.get(`/region/${regionId}`).then(response => response.data.region);

export const getRegionStats = async (regionId: number): Promise<IRegionStatsResponse> =>
    basecamp.get(`/region/${regionId}/stats`).then(response => response.data);

export const getCountyZipcodeHierarchy = async (regionId: number): Promise<{ region?: Record<string, string[]>; all: Record<string, string[]> }> =>
    basecamp.get(`/regions/${regionId}/hierachy`).then(response => response.data);

export const getSellingRegions = async (): Promise<ISellingRegion[]> => basecamp.get('/selling-regions').then(response => response.data);

export const saveSellingRegion = async (region: ISellingRegion): Promise<ISellingRegion[]> => {
    const op = region.sellingRegionId ? basecamp.patch(`/selling-regions/${region.sellingRegionId}`, region) : basecamp.post('/selling-regions', region);
    return op.then(response => response.data);
};

export const deleteSellingRegion = async (sellingRegionId: number): Promise<ISellingRegion[]> =>
    basecamp.delete(`/selling-regions/${sellingRegionId}`).then(response => response.data);
