import client from "../../client";
import type { ResponseConfig } from "../../client";
import type { GetQueryResponse } from "../models/Get";
import type { BatchPublishEstatesMutationRequest, BatchPublishEstatesMutationResponse } from "../models/sourcingStorageModels/BatchPublishEstates";
import type { CreateTableSnapshotRequestMutationRequest, CreateTableSnapshotRequestMutationResponse } from "../models/sourcingStorageModels/CreateTableSnapshotRequest";
import type { CreateTableSnapshotStopRequestMutationRequest, CreateTableSnapshotStopRequestMutationResponse } from "../models/sourcingStorageModels/CreateTableSnapshotStopRequest";
import type { GetEstateByOriginalIdPathParams, GetEstateByOriginalIdQueryResponse } from "../models/sourcingStorageModels/GetEstateByOriginalId";
import type { GetOutdatedEstatesMutationRequest, GetOutdatedEstatesMutationResponse } from "../models/sourcingStorageModels/GetOutdatedEstates";
import type { PublishEstateByOriginalIdMutationRequest, PublishEstateByOriginalIdMutationResponse } from "../models/sourcingStorageModels/PublishEstateByOriginalId";
import type { PublishRandomEstateMutationRequest, PublishRandomEstateMutationResponse } from "../models/sourcingStorageModels/PublishRandomEstate";
import type { UnpublishOutdatedEstatesMutationResponse, UnpublishOutdatedEstatesQueryParams } from "../models/sourcingStorageModels/UnpublishOutdatedEstates";

 /**
 * @link /
 */
export async function get(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetQueryResponse>["data"]> {
    const res = await client<GetQueryResponse>({ method: "get", url: `/`, ...options });
    return res.data;
}

 /**
 * @description (Re-) Publishes a random estate (mainly for local testing purposes)
 * @link /api/maintenance/publish-random
 */
export async function publishRandomEstate(data: PublishRandomEstateMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<PublishRandomEstateMutationResponse>["data"]> {
    const res = await client<PublishRandomEstateMutationResponse, PublishRandomEstateMutationRequest>({ method: "post", url: `/api/maintenance/publish-random`, data, ...options });
    return res.data;
}

 /**
 * @description (Re-) Publishes an estate by original-id
 * @link /api/maintenance/publish-estate
 */
export async function publishEstateByOriginalId(data: PublishEstateByOriginalIdMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<PublishEstateByOriginalIdMutationResponse>["data"]> {
    const res = await client<PublishEstateByOriginalIdMutationResponse, PublishEstateByOriginalIdMutationRequest>({ method: "post", url: `/api/maintenance/publish-estate`, data, ...options });
    return res.data;
}

 /**
 * @description Gets the latest estate by original id
 * @link /api/maintenance/estates/:originalId
 */
export async function getEstateByOriginalId({ originalId }: {
    originalId: GetEstateByOriginalIdPathParams["originalId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetEstateByOriginalIdQueryResponse>["data"]> {
    const res = await client<GetEstateByOriginalIdQueryResponse>({ method: "get", url: `/api/maintenance/estates/${originalId}`, ...options });
    return res.data;
}

 /**
 * @description Batch (force) publishes estates by time-range
 * @link /api/maintenance/batch-publish
 */
export async function batchPublishEstates(data?: BatchPublishEstatesMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<BatchPublishEstatesMutationResponse>["data"]> {
    const res = await client<BatchPublishEstatesMutationResponse, BatchPublishEstatesMutationRequest>({ method: "post", url: `/api/maintenance/batch-publish`, data, ...options });
    return res.data;
}

 /**
 * @description Force trigger unpublish flow of outdated (old) estates
 * @link /api/maintenance/unpublish-outdated
 */
export async function unpublishOutdatedEstates(params?: UnpublishOutdatedEstatesQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UnpublishOutdatedEstatesMutationResponse>["data"]> {
    const res = await client<UnpublishOutdatedEstatesMutationResponse>({ method: "post", url: `/api/maintenance/unpublish-outdated`, params, ...options });
    return res.data;
}

 /**
 * @description creates debezium incremental snapshot request
 * @link /api/maintenance/table-snapshot-request
 */
export async function createTableSnapshotRequest(data: CreateTableSnapshotRequestMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateTableSnapshotRequestMutationResponse>["data"]> {
    const res = await client<CreateTableSnapshotRequestMutationResponse, CreateTableSnapshotRequestMutationRequest>({ method: "post", url: `/api/maintenance/table-snapshot-request`, data, ...options });
    return res.data;
}

 /**
 * @description creates debezium incremental snapshot stop request
 * @link /api/maintenance/table-snapshot-stop-request
 */
export async function createTableSnapshotStopRequest(data: CreateTableSnapshotStopRequestMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateTableSnapshotStopRequestMutationResponse>["data"]> {
    const res = await client<CreateTableSnapshotStopRequestMutationResponse, CreateTableSnapshotStopRequestMutationRequest>({ method: "post", url: `/api/maintenance/table-snapshot-stop-request`, data, ...options });
    return res.data;
}

 /**
 * @description Returns list of outdated estates
 * @link /api/estates/outdated
 */
export async function getOutdatedEstates(data: GetOutdatedEstatesMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetOutdatedEstatesMutationResponse>["data"]> {
    const res = await client<GetOutdatedEstatesMutationResponse, GetOutdatedEstatesMutationRequest>({ method: "post", url: `/api/estates/outdated`, data, ...options });
    return res.data;
}

 export const operations = {
    "get_": {
        "path": "/",
        "method": "get"
    },
    "publishRandomEstate": {
        "path": "/api/maintenance/publish-random",
        "method": "post"
    },
    "publishEstateByOriginalId": {
        "path": "/api/maintenance/publish-estate",
        "method": "post"
    },
    "getEstateByOriginalId": {
        "path": "/api/maintenance/estates/:originalId",
        "method": "get"
    },
    "batchPublishEstates": {
        "path": "/api/maintenance/batch-publish",
        "method": "post"
    },
    "unpublishOutdatedEstates": {
        "path": "/api/maintenance/unpublish-outdated",
        "method": "post"
    },
    "createTableSnapshotRequest": {
        "path": "/api/maintenance/table-snapshot-request",
        "method": "post"
    },
    "createTableSnapshotStopRequest": {
        "path": "/api/maintenance/table-snapshot-stop-request",
        "method": "post"
    },
    "getOutdatedEstates": {
        "path": "/api/estates/outdated",
        "method": "post"
    }
} as const;