import { IUser } from '@condo/domain';
import type { ICockpitConfig } from '../types';

export const initGleap = async (config: ICockpitConfig, user: IUser) => {
    if (!config.GLEAP_ENABLED) {
        return;
    }
    const { default: gleap } = await import('gleap');

    gleap.initialize(config.GLEAP_API_KEY);
    gleap.setAppBuildNumber(config.HOUSEHOLD_VERSION);
    gleap.setCustomData('environment', config.ENVIRONMENT_NAME);
    gleap.setCustomData('version', config.HOUSEHOLD_VERSION);
    gleap.identify(user.uid, { email: user.email, name: user.displayName });

    return gleap;
};
