import type { App } from 'vue';

import {
    CondoAutoComplete,
    CondoButton,
    CondoDatePicker,
    CondoField,
    CondoIcon,
    CondoMultiSelect,
    CondoNumericInput,
    CondoRangeSlider,
    CondoSelect,
    CondoSwitch,
    CondoTag,
    CondoThreeStateSwitch,
    CondoTwoStateSwitch,
    CondoUpload,
    ModalWrapper,
    PercentageOrMoneyInput,
    QueryBuilder,
} from '@condo/ui';

export const registerCondoSharedComponents = (app: App) => {
    app.component('condo-auto-complete', CondoAutoComplete);
    app.component('condo-button', CondoButton);
    app.component('condo-date-picker', CondoDatePicker);
    app.component('condo-field', CondoField);
    app.component('condo-icon', CondoIcon);
    app.component('condo-multiselect', CondoMultiSelect);
    app.component('condo-numeric-input', CondoNumericInput);
    app.component('condo-range-slider', CondoRangeSlider);
    app.component('condo-select', CondoSelect);
    app.component('condo-switch', CondoSwitch);
    app.component('condo-tag', CondoTag);
    app.component('condo-upload', CondoUpload);
    app.component('percentage-or-money-input', PercentageOrMoneyInput);
    app.component('modal-wrapper', ModalWrapper);
    app.component('query-builder', QueryBuilder);
    app.component('condo-three-state-switch', CondoThreeStateSwitch);
    app.component('condo-two-state-switch', CondoTwoStateSwitch);
};
