import { getRentableUnits } from '@/api/rentable-units.api';
import type { IRentableUnit } from '@condo/domain';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IRentableUnitStore {
    rentableUnits: IRentableUnit[];
}

function getInitialState(): IRentableUnitStore {
    return {
        rentableUnits: [],
    };
}

const getters: GetterTree<IRentableUnitStore, any> = {
    rentableUnits(state: IRentableUnitStore): IRentableUnit[] {
        return state.rentableUnits;
    },
    priorityRules(state: IRentableUnitStore): IRentableUnit[] {
        return state.rentableUnits;
    },
};

const actions: ActionTree<IRentableUnitStore, any> = {
    async fetchRentableUnits({ commit }, { estateId }: { estateId: number }) {
        commit('setRentableUnits', await getRentableUnits(estateId));
    },
};

const mutations: MutationTree<IRentableUnitStore> = {
    setRentableUnits(state, rentableUnits: IRentableUnit[]) {
        state.rentableUnits = rentableUnits;
    },
};

export const rentableUnitStore = {
    namespaced: true,
    state: getInitialState(),
    actions,
    getters,
    mutations,
};
