import type { RouteRecordRaw } from 'vue-router';

import { getStrippedCCSRoutes } from '@condo/domain';

const CCSRouteMap = getStrippedCCSRoutes();
export const salesPackageRoutes: RouteRecordRaw = {
    ...CCSRouteMap['sales-package.one'],
    component: () => import('@/components/sales-package/SalesPackage.vue'),
    props: route => ({ salesPackageId: route.params.salesPackageId }),
    redirect: params => `${params.fullPath}/${CCSRouteMap['sales-package.overview'].path}`,
    children: [
        {
            ...CCSRouteMap['sales-package.overview'],
            components: {
                default: () => import('@/components/sales-package/SalesPackageOverview.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['sales-package.checklist'],
            components: {
                default: () => import('@/components/sales-package/SalesPackageChecklist.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['sales-package.notary-sheet'],
            components: {
                default: () => import('@/components/sales-package/SalesPackageNotarySheet.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['sales-package.data-sheet'],
            components: {
                default: () => import('@/components/sales-package/SalesPackageDataSheet.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['sales-package.final-sheet'],
            components: {
                default: () => import('@/components/sales-package/SalesPackageFinalSheet.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['sales-package.history'],
            components: {
                default: () => import('@/components/sales-package/SalesPackageHistory.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['sales-package.edit'],
            components: {
                default: () => import('@/components/sales-package/SalesPackageEdit.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['sales-package.documents'],
            components: {
                default: () => import('@/components/sales-package/SalesPackageDocument.vue'),
            },
            props: true,
        },
    ],
};
