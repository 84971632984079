import type { IConfigData, IConfigHistory, IConfigObject, IConfigSearchParams, IRegion, IRegionStats } from '@condo/domain';

import { fetchActiveConfigAttributesPaths, fetchConfigHistory, fetchConfigWithParentByVersion, saveConfigRecords } from '@/api/basecamp.api';
import { createRegion, getRegion, getRegionStats, getRegions, updateRegion } from '@/api/region.api';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IRegionStore {
    regions: IRegion[];
    region?: IRegion;
    stats?: IRegionStats;
    activeAttributePaths: string[];
    history: IConfigHistory[];
    currentConfig: {
        config?: IConfigObject;
        parent?: IConfigObject;
    };
}

const getInitialState = (): IRegionStore => ({
    regions: [],
    region: null!,
    stats: null!,
    activeAttributePaths: [],
    history: [],
    currentConfig: {},
});

const getters: GetterTree<IRegionStore, any> = {
    region(state: IRegionStore): IRegion | undefined {
        return state.region;
    },
    regions(state: IRegionStore): IRegion[] {
        return state.regions;
    },
    stats(state: IRegionStore): IRegionStats | undefined {
        return state.stats;
    },

    activeAttributePaths(state: IRegionStore): string[] {
        return state.activeAttributePaths;
    },
    history(state: IRegionStore): IConfigHistory[] {
        return state.history;
    },
    maxVersion(state: IRegionStore): string {
        return state.history[0]!.encodedIdPath;
    },
    currentConfig(state: IRegionStore): IConfigObject | undefined {
        return state.currentConfig.config;
    },
    currentConfigParent(state: IRegionStore): IConfigObject | undefined {
        return state.currentConfig.parent;
    },
};

const actions: ActionTree<IRegionStore, any> = {
    async getRegions({ commit }) {
        const regions = await getRegions();
        commit('setRegions', { regions });

        return regions;
    },
    async getRegion({ commit }, regionId: number) {
        const region = await getRegion(regionId);
        commit('setRegion', { region });

        return region;
    },
    async getRegionStats({ commit }, regionId: number) {
        const { region, stats } = await getRegionStats(regionId);
        commit('setRegion', { region });
        commit('setRegionStats', { stats });

        return stats;
    },
    async loadActiveConfigAttributesPaths({ commit }, attributes: IConfigSearchParams) {
        const data = await fetchActiveConfigAttributesPaths(attributes);
        commit('setActiveAttributePaths', data);
    },
    async loadHistory({ commit }, attributePath: string) {
        const data = await fetchConfigHistory(attributePath);
        commit('setHistory', data);
        return data;
    },
    async loadConfigWithParentByVersion({ commit }, version: number) {
        const data = await fetchConfigWithParentByVersion(version);
        commit('setCurrentConfig', data);
    },
    async updateConfig({ commit }, params: { configRow: IConfigData; attributePath: string }) {
        const data = await saveConfigRecords(params.configRow, params.attributePath);
        commit('pushHistory', data);
    },
    async saveRegion({ commit }, region: IRegion) {
        const dbRegion = region.regionId ? await updateRegion(region) : await createRegion(region);

        commit('setRegion', { region: dbRegion });

        return dbRegion;
    },
};

const mutations: MutationTree<IRegionStore> = {
    setRegion(state: IRegionStore, { region }) {
        state.region = region;
    },
    setRegions(state: IRegionStore, { regions }) {
        state.regions = regions;
    },

    setRegionStats(state: IRegionStore, { stats }) {
        state.stats = stats;
    },

    setActiveAttributePaths(state: IRegionStore, activeAttributePaths: string[]) {
        state.activeAttributePaths = activeAttributePaths;
    },
    setHistory(state: IRegionStore, history: IConfigHistory[]) {
        state.history = history;
    },
    setCurrentConfig(state: IRegionStore, { config, parent }: { config: IConfigObject; parent?: IConfigObject }) {
        state.currentConfig = { config, parent };
    },
    pushHistory(state: IRegionStore, record: IConfigHistory) {
        state.history = [record, ...state.history];
    },
};

export const regionStore = {
    namespaced: true,
    state: getInitialState(),
    getters,
    actions,
    mutations,
};
