import axios, { type AxiosInstance } from 'axios';
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
// =================== ATTENTION ===================
// This file is required for the generated sdk, do not change.
// It is used as the base for all sdks (e.g. cockpit)
//
// For new clients copy-pasta this file as is and install 'qs' + '@types/qs' + 'axios'
// =================================================
import qs from 'qs';

/**
 * Subset of AxiosRequestConfig
 */
export type RequestConfig<TData = unknown> = {
    url?: string;
    method: 'get' | 'put' | 'patch' | 'post' | 'delete';
    params?: unknown;
    data?: TData;
    responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
    signal?: AbortSignal;
    headers?: AxiosRequestConfig['headers'];
};

/**
 * Subset of AxiosResponse
 */
export type ResponseConfig<TData = unknown> = {
    data: TData;
    status: number;
    statusText: string;
    headers?: AxiosResponse['headers'];
};

// @note global axios-sdk-instance, right now there is no other way until kubb has a different sdk-output
const axiosInstance = axios.create({
    paramsSerializer: {
        serialize: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
});

// @note this is used in sdk-initialization, mainly to set error-behaviour and baseUrl
export function initHTTPClient(
    baseURL: string,
    onRequest?: <T extends AxiosRequestConfig>(config: T) => T | Promise<T>,
    onRejectedResponse?: (error: any) => any,
): AxiosInstance {
    axiosInstance.defaults.baseURL = baseURL;
    if (onRequest) {
        axiosInstance.interceptors.request.use(onRequest);
    }
    if (onRejectedResponse) {
        axiosInstance.interceptors.response.use(response => response, onRejectedResponse);
    }

    return axiosInstance;
}

// @note this is used in ./gen/clients
const axiosClient = async <TData, TError = unknown, TVariables = unknown>(config: RequestConfig<TVariables>): Promise<ResponseConfig<TData>> => {
    return axiosInstance.request<TVariables, ResponseConfig<TData>>({ ...config }).catch((e: AxiosError<TError>) => {
        throw e;
    });
};

export default axiosClient;
