import type { RouteRecordRaw } from 'vue-router';

import { getStrippedCCSRoutes } from '@condo/domain';

const CCSRouteMap = getStrippedCCSRoutes();
export const creditLinePortfolioRoutes: RouteRecordRaw = {
    ...CCSRouteMap['credit-line-portfolio.one'],
    component: () => import('@/components/credit-line-portfolio/CreditLinePortfolio.vue'),
    props: route => ({ creditLinePortfolioId: +route.params.creditLinePortfolioId }),
    redirect: params => `${params.fullPath}/${CCSRouteMap['credit-line-portfolio.overview'].path}`,
    children: [
        {
            ...CCSRouteMap['credit-line-portfolio.overview'],
            components: {
                default: () => import('@/components/credit-line-portfolio/CreditLinePortfolioOverview.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['credit-line-portfolio.predictions'],
            components: {
                default: () => import('@/components/credit-line-portfolio/CreditLinePortfolioCashflowPredictions.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['credit-line-portfolio.budget-costs'],
            components: {
                default: () => import('@/components/credit-line-portfolio/CreditLinePortfolioBudgetCosts.vue'),
            },
            props: true,
        },
    ],
};
