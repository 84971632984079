import { findEligibleEstatesWithDocuments, findSellingEstates } from '@/api/selling.api';
import type { ISellingEstate } from '@condo/domain';
import { currency } from '@condo/formatters';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export interface ISellingStore {
    estates: ISellingEstate[];
    eligibleEstatesWithDocuments: ISellingEstate[];
    total: number;
    totalEligibleEstatesWithDocuments: number;
}

function getInitialState(): ISellingStore {
    return {
        estates: [],
        total: 0,
        eligibleEstatesWithDocuments: [],
        totalEligibleEstatesWithDocuments: 0,
    };
}

// initial state
const state = getInitialState();

const getters: GetterTree<ISellingStore, any> = {
    estates(state) {
        return state.estates;
    },
    total(state) {
        return state.total;
    },
    eligibleEstatesWithDocuments(state) {
        return state.eligibleEstatesWithDocuments;
    },
    totalEligibleEstatesWithDocuments(state) {
        return state.totalEligibleEstatesWithDocuments;
    },
};

const actions: ActionTree<ISellingStore, any> = {
    async getSellingEstates({ commit }, params) {
        const response = await findSellingEstates(params);
        const { estates, total } = response;

        const prepared = estates.map(data => ({
            ...data,
            portfolioIdentity: data.portfolio?.shortUuid || data.portfolio?.portfolioId || '',
            executedInvestmentCase: {
                ...data.executedInvestmentCase,
                expectedSalesPrice: currency(data.executedInvestmentCase?.expectedSalesPrice ?? null, 0, 0),
            },
        }));

        commit('setEstates', { estates: prepared, total });

        return estates;
    },
    async getEligibleEstatesWithDocuments({ commit }, params) {
        const { estates, total } = await findEligibleEstatesWithDocuments(params);

        commit('setEligibleEstatesWithDocuments', { estates, total });

        return estates;
    },
};

const mutations: MutationTree<ISellingStore> = {
    setEstates(state, { estates, total }) {
        state.estates = estates;
        state.total = total;
    },
    setEligibleEstatesWithDocuments(state, { estates, total }) {
        state.eligibleEstatesWithDocuments = estates;
        state.totalEligibleEstatesWithDocuments = total;
    },
};

export const sellingStore: Module<ISellingStore, any> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
