export interface IUIStore {
    isLoading: boolean;
    isFooterBarVisible: boolean;
    isAsideMobileExpanded: null | boolean;
    environment: string;
    houseHoldVersion: string;
    isSideBarReduced: boolean;
    portfolioTableVisible: boolean;
}

function getInitialState(): IUIStore {
    let portfolioTableVisible: string | boolean = localStorage.getItem('portfolio-table-visible');
    if (portfolioTableVisible === null) {
        portfolioTableVisible = true;
    } else {
        portfolioTableVisible = !!+portfolioTableVisible;
    }
    return {
        /* Spinner */
        isLoading: false,

        /* FooterBar */
        isFooterBarVisible: true,

        /* Aside */
        isAsideMobileExpanded: null,
        environment: 'local',
        houseHoldVersion: '(unknown)',
        isSideBarReduced: false,
        portfolioTableVisible,
    };
}

export const uiStore = {
    namespaced: true,
    state: getInitialState(),
    getters: {
        isLoading(state: IUIStore) {
            return state.isLoading;
        },
        isFooterBarVisible(state: IUIStore) {
            return state.isFooterBarVisible;
        },
        isAsideMobileExpanded(state: IUIStore) {
            return state.isAsideMobileExpanded;
        },
        houseHoldVersion(state: IUIStore) {
            return state.houseHoldVersion;
        },
        isSideBarReduced(state: IUIStore) {
            //return state.isSideBarReduced;
            state.isSideBarReduced = !!localStorage.getItem('isSideBarReduced');
            return state.isSideBarReduced;
        },
        portfolioTableVisible(state: IUIStore) {
            return state.portfolioTableVisible;
        },
        environment(state: IUIStore) {
            return state.environment;
        },
    },
    mutations: {
        toggleLoading(state: IUIStore, loadingState: boolean) {
            if (loadingState === undefined) {
                state.isLoading = !state.isLoading;
                return;
            }

            state.isLoading = !!loadingState;
            return;
        },
        togglePortfolioTableVisible(state: IUIStore, val: boolean) {
            state.portfolioTableVisible = val;
            localStorage.setItem('portfolio-table-visible', +val as unknown as string);
        },
        setSystemVersion(state: IUIStore, version: string) {
            state.houseHoldVersion = version || '[unknown]';
        },
        setEnvironment(state: IUIStore, env: string) {
            state.environment = env;
        },
        setSideBarReduced(state: IUIStore, reduce: boolean) {
            state.isSideBarReduced = reduce;
            if (reduce) {
                localStorage.setItem('isSideBarReduced', 'yes');
            } else {
                localStorage.removeItem('isSideBarReduced');
            }
        },
    },
};
