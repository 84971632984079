import type { IRule } from '@condo/domain';
import { basecamp } from './api-client';

export const getRules = async (): Promise<IRule[]> => basecamp.get('/rules').then(response => response.data);

export const createRule = async (params: { ruleName: string; condCode: string; regions: string[] }): Promise<IRule> =>
    basecamp.post('/rules', params).then(response => response.data);

export const updateRule = async (id: number, params: { ruleName?: string; condCode?: string; regions?: string[] }): Promise<Partial<IRule>> =>
    basecamp.patch(`/rules/${id}`, params).then(response => response.data);

export const deleteRule = async (id: number): Promise<IRule> => basecamp.delete(`/rules/${id}`).then(response => response.data);
