import type { ICockpitConfig } from '@/types';
import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

export const initSentry = (config: ICockpitConfig, app: App) => {
    if (!config.SENTRY_ENABLED) {
        return;
    }
    Sentry.init({
        app,
        debug: config.SENTRY_DEBUG,
        dsn: config.SENTRY_DSN,
        environment: config.ENVIRONMENT_NAME,
        release: `${config.HOUSEHOLD_VERSION}-core`,
        enabled: true,
        ignoreErrors: [
            // @note strings are partial matches in sentry
            'ChunkLoadError',
            'ResizeObserver loop completed with undelivered notification',
            /code 4\d{2}/,
            'Importing a module script failed',
            /Failed to fetch dynamically imported module/gi,
            /is not a valid JavaScript MIME type/gi,
            /NavigationDuplicated/gi,
            /Network/gi,
            'Unexpected token',
            /Navigation cancelled/gi,
            /Unable to preload CSS/gi,
            /^Loading chunk.*failed$/gi,
            /^Loading CSS chunk.*failed$/gi,
        ],
    });
    Sentry.setTag('server_name', config.SERVICE_NAME);
};
