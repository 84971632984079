import client from "../../client";
import type { ResponseConfig } from "../../client";
import type { GetQueryResponse } from "../models/Get";
import type { CreateMfhOpenQuestionMutationRequest, CreateMfhOpenQuestionMutationResponse } from "../models/mfhModels/CreateMfhOpenQuestion";
import type { DeleteMfhOpenQuestionMutationResponse, DeleteMfhOpenQuestionPathParams } from "../models/mfhModels/DeleteMfhOpenQuestion";
import type { GetMfhPathParams, GetMfhQueryParams, GetMfhQueryResponse } from "../models/mfhModels/GetMfh";
import type { GetMfhCalendarEventsQueryParams, GetMfhCalendarEventsQueryResponse } from "../models/mfhModels/GetMfhCalendarEvents";
import type { GetMfhDocumentsPathParams, GetMfhDocumentsQueryResponse } from "../models/mfhModels/GetMfhDocuments";
import type { GetMfhListQueryParams, GetMfhListQueryResponse } from "../models/mfhModels/GetMfhList";
import type { GetMfhMediasPathParams, GetMfhMediasQueryResponse } from "../models/mfhModels/GetMfhMedias";
import type { GetMfhOpenQuestionsPathParams, GetMfhOpenQuestionsQueryResponse } from "../models/mfhModels/GetMfhOpenQuestions";
import type { GetMfhRentRollPathParams, GetMfhRentRollQueryResponse } from "../models/mfhModels/GetMfhRentRoll";
import type { GetMfhddQuestionsPathParams, GetMfhddQuestionsQueryParams, GetMfhddQuestionsQueryResponse } from "../models/mfhModels/GetMfhddQuestions";
import type { UpdateMfhDocumentMutationRequest, UpdateMfhDocumentMutationResponse, UpdateMfhDocumentPathParams } from "../models/mfhModels/UpdateMfhDocument";
import type { UpdateMfhOpenQuestionMutationRequest, UpdateMfhOpenQuestionMutationResponse, UpdateMfhOpenQuestionPathParams } from "../models/mfhModels/UpdateMfhOpenQuestion";
import type { UpdateMfhRentRollMutationRequest, UpdateMfhRentRollMutationResponse, UpdateMfhRentRollPathParams } from "../models/mfhModels/UpdateMfhRentRoll";
import type { UpdateMfhddAnswerMutationRequest, UpdateMfhddAnswerMutationResponse, UpdateMfhddAnswerPathParams } from "../models/mfhModels/UpdateMfhddAnswer";

 /**
 * @link /
 */
export async function get(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetQueryResponse>["data"]> {
    const res = await client<GetQueryResponse>({ method: "get", url: `/`, ...options });
    return res.data;
}

 /**
 * @description Get list of mfhs
 * @link /api/mfhs
 */
export async function getMfhList(params?: GetMfhListQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMfhListQueryResponse>["data"]> {
    const res = await client<GetMfhListQueryResponse>({ method: "get", url: `/api/mfhs`, params, ...options });
    return res.data;
}

 /**
 * @description Get MFH
 * @link /api/mfhs/:mfhId
 */
export async function getMfh({ mfhId }: {
    mfhId: GetMfhPathParams["mfhId"];
}, params: GetMfhQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMfhQueryResponse>["data"]> {
    const res = await client<GetMfhQueryResponse>({ method: "get", url: `/api/mfhs/${mfhId}`, params, ...options });
    return res.data;
}

 /**
 * @description Get MFH Documents
 * @link /api/mfhs/:mfhId/documents
 */
export async function getMfhDocuments({ mfhId }: {
    mfhId: GetMfhDocumentsPathParams["mfhId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMfhDocumentsQueryResponse>["data"]> {
    const res = await client<GetMfhDocumentsQueryResponse>({ method: "get", url: `/api/mfhs/${mfhId}/documents`, ...options });
    return res.data;
}

 /**
 * @description Update MFH Document
 * @link /api/mfhs/:mfhId/documents/:documentId
 */
export async function updateMfhDocument({ mfhId, documentId }: {
    mfhId: UpdateMfhDocumentPathParams["mfhId"];
    documentId: UpdateMfhDocumentPathParams["documentId"];
}, data: UpdateMfhDocumentMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateMfhDocumentMutationResponse>["data"]> {
    const res = await client<UpdateMfhDocumentMutationResponse, UpdateMfhDocumentMutationRequest>({ method: "patch", url: `/api/mfhs/${mfhId}/documents/${documentId}`, data, ...options });
    return res.data;
}

 /**
 * @description Get MFH Medias
 * @link /api/mfhs/:mfhId/medias
 */
export async function getMfhMedias({ mfhId }: {
    mfhId: GetMfhMediasPathParams["mfhId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMfhMediasQueryResponse>["data"]> {
    const res = await client<GetMfhMediasQueryResponse>({ method: "get", url: `/api/mfhs/${mfhId}/medias`, ...options });
    return res.data;
}

 /**
 * @description Get MFH DD Questions
 * @link /api/mfhs/:mfhId/dd/questions
 */
export async function getMfhddQuestions({ mfhId }: {
    mfhId: GetMfhddQuestionsPathParams["mfhId"];
}, params: GetMfhddQuestionsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMfhddQuestionsQueryResponse>["data"]> {
    const res = await client<GetMfhddQuestionsQueryResponse>({ method: "get", url: `/api/mfhs/${mfhId}/dd/questions`, params, ...options });
    return res.data;
}

 /**
 * @description Update MFH DD Answer
 * @link /api/mfhs/:mfhId/dd/answers
 */
export async function updateMfhddAnswer({ mfhId }: {
    mfhId: UpdateMfhddAnswerPathParams["mfhId"];
}, data: UpdateMfhddAnswerMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateMfhddAnswerMutationResponse>["data"]> {
    const res = await client<UpdateMfhddAnswerMutationResponse, UpdateMfhddAnswerMutationRequest>({ method: "put", url: `/api/mfhs/${mfhId}/dd/answers`, data, ...options });
    return res.data;
}

 /**
 * @description Update MFH Rent Roll
 * @link /api/mfhs/:mfhId/:estateId/rent-roll
 */
export async function updateMfhRentRoll({ mfhId, estateId }: {
    mfhId: UpdateMfhRentRollPathParams["mfhId"];
    estateId: UpdateMfhRentRollPathParams["estateId"];
}, data: UpdateMfhRentRollMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateMfhRentRollMutationResponse>["data"]> {
    const res = await client<UpdateMfhRentRollMutationResponse, UpdateMfhRentRollMutationRequest>({ method: "patch", url: `/api/mfhs/${mfhId}/${estateId}/rent-roll`, data, ...options });
    return res.data;
}

 /**
 * @description Get MFH Open Questions
 * @link /api/mfhs/:mfhId/open-questions
 */
export async function getMfhOpenQuestions({ mfhId }: {
    mfhId: GetMfhOpenQuestionsPathParams["mfhId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMfhOpenQuestionsQueryResponse>["data"]> {
    const res = await client<GetMfhOpenQuestionsQueryResponse>({ method: "get", url: `/api/mfhs/${mfhId}/open-questions`, ...options });
    return res.data;
}

 /**
 * @description Get MFH Rent Roll
 * @link /api/mfhs/:mfhId/rent-roll
 */
export async function getMfhRentRoll({ mfhId }: {
    mfhId: GetMfhRentRollPathParams["mfhId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMfhRentRollQueryResponse>["data"]> {
    const res = await client<GetMfhRentRollQueryResponse>({ method: "get", url: `/api/mfhs/${mfhId}/rent-roll`, ...options });
    return res.data;
}

 /**
 * @description Create MFH Open Questions
 * @link /api/mfhs/open-questions
 */
export async function createMfhOpenQuestion(data: CreateMfhOpenQuestionMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateMfhOpenQuestionMutationResponse>["data"]> {
    const res = await client<CreateMfhOpenQuestionMutationResponse, CreateMfhOpenQuestionMutationRequest>({ method: "post", url: `/api/mfhs/open-questions`, data, ...options });
    return res.data;
}

 /**
 * @description Update MFH Open Questions
 * @link /api/mfhs/open-questions/:openQuestionId
 */
export async function updateMfhOpenQuestion({ openQuestionId }: {
    openQuestionId: UpdateMfhOpenQuestionPathParams["openQuestionId"];
}, data: UpdateMfhOpenQuestionMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateMfhOpenQuestionMutationResponse>["data"]> {
    const res = await client<UpdateMfhOpenQuestionMutationResponse, UpdateMfhOpenQuestionMutationRequest>({ method: "put", url: `/api/mfhs/open-questions/${openQuestionId}`, data, ...options });
    return res.data;
}

 /**
 * @description Delete MFH Open Questions
 * @link /api/mfhs/open-questions/:openQuestionId
 */
export async function deleteMfhOpenQuestion({ openQuestionId }: {
    openQuestionId: DeleteMfhOpenQuestionPathParams["openQuestionId"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteMfhOpenQuestionMutationResponse>["data"]> {
    const res = await client<DeleteMfhOpenQuestionMutationResponse>({ method: "delete", url: `/api/mfhs/open-questions/${openQuestionId}`, ...options });
    return res.data;
}

 /**
 * @description Get list of mfhs calendar events where current user is attendee
 * @link /api/mfhs/calendar-events
 */
export async function getMfhCalendarEvents(params?: GetMfhCalendarEventsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMfhCalendarEventsQueryResponse>["data"]> {
    const res = await client<GetMfhCalendarEventsQueryResponse>({ method: "get", url: `/api/mfhs/calendar-events`, params, ...options });
    return res.data;
}

 export const operations = {
    "get_": {
        "path": "/",
        "method": "get"
    },
    "getMFHList": {
        "path": "/api/mfhs",
        "method": "get"
    },
    "getMFH": {
        "path": "/api/mfhs/:mfhId",
        "method": "get"
    },
    "getMFHDocuments": {
        "path": "/api/mfhs/:mfhId/documents",
        "method": "get"
    },
    "updateMFHDocument": {
        "path": "/api/mfhs/:mfhId/documents/:documentId",
        "method": "patch"
    },
    "getMFHMedias": {
        "path": "/api/mfhs/:mfhId/medias",
        "method": "get"
    },
    "getMFHDDQuestions": {
        "path": "/api/mfhs/:mfhId/dd/questions",
        "method": "get"
    },
    "updateMFHDDAnswer": {
        "path": "/api/mfhs/:mfhId/dd/answers",
        "method": "put"
    },
    "updateMFHRentRoll": {
        "path": "/api/mfhs/:mfhId/:estateId/rent-roll",
        "method": "patch"
    },
    "getMFHOpenQuestions": {
        "path": "/api/mfhs/:mfhId/open-questions",
        "method": "get"
    },
    "getMFHRentRoll": {
        "path": "/api/mfhs/:mfhId/rent-roll",
        "method": "get"
    },
    "createMFHOpenQuestion": {
        "path": "/api/mfhs/open-questions",
        "method": "post"
    },
    "updateMFHOpenQuestion": {
        "path": "/api/mfhs/open-questions/:openQuestionId",
        "method": "put"
    },
    "deleteMFHOpenQuestion": {
        "path": "/api/mfhs/open-questions/:openQuestionId",
        "method": "delete"
    },
    "getMfhCalendarEvents": {
        "path": "/api/mfhs/calendar-events",
        "method": "get"
    }
} as const;