import { basecamp } from '@/api/api-client';
import { openDownloadLink, showErrorMessage } from '@/shared';
import {
    type IEstate,
    type IPaymentNoticeSummariesParams,
    type IPaymentNoticeSummary,
    type IPurchasingEstate,
    type IPurchasingEstatesQuery,
    InvariantError,
    PaymentNoticeSummary,
} from '@condo/domain';
import dayjs from 'dayjs';

export const findPurchasingEstates = async (params?: IPurchasingEstatesQuery): Promise<{ estates: IPurchasingEstate[]; total: number }> =>
    basecamp.get('/purchasing/estates', { params }).then(response => {
        const { estates, total } = response.data;
        return { estates, total };
    });

export const fetchEstatePaymentNoticeSummary = async (estateId: number): Promise<PaymentNoticeSummary | undefined> =>
    basecamp
        .get(`/estates/${estateId}/payment-notice-summary`)
        .then(response => (response.data.paymentNoticeSummary ? new PaymentNoticeSummary(response.data.paymentNoticeSummary) : undefined));

export const fetchPaymentNoticeSummaries = async (params?: IPaymentNoticeSummariesParams): Promise<PaymentNoticeSummary[]> =>
    basecamp.get(`/payment-notice-summaries`, { params }).then(response => {
        const paymentNoticeSummaries = response.data.paymentNoticeSummaries;
        return paymentNoticeSummaries.map(pm => new PaymentNoticeSummary(pm));
    });

export const createOrUpdatePaymentNoticeSummary = async (
    estateId: number,
    paymentNoticeSummary: Partial<IPaymentNoticeSummary>,
): Promise<PaymentNoticeSummary> =>
    basecamp
        .put(`/estates/${estateId}/payment-notice-summary`, paymentNoticeSummary)
        .then(response => new PaymentNoticeSummary(response.data.paymentNoticeSummary));

export const approvePaymentNoticeSummary = async (paymentNoticeSummaryId: number): Promise<void> =>
    basecamp.post(`/payment-notice-summaries/${paymentNoticeSummaryId}/approve`);

export const unapprovePaymentNoticeSummary = async (paymentNoticeSummaryId: number): Promise<void> =>
    basecamp.post(`/payment-notice-summaries/${paymentNoticeSummaryId}/unapprove`);

export const setPaymentNoticeSummaryAsReadyForReview = async (paymentNoticeSummaryId: number): Promise<void> =>
    basecamp.post(`/payment-notice-summaries/${paymentNoticeSummaryId}/ready-for-review`);

export const exportPaymentNoticeSummary = async (paymentNoticeSummary: IPaymentNoticeSummary, estate?: IEstate): Promise<Buffer> => {
    const paymentNoticeSummaryEstate = estate ?? paymentNoticeSummary.estate;
    if (!paymentNoticeSummaryEstate) {
        throw new InvariantError('Need to have an estate attached to payment-notice prior to exporting');
    }
    const { data } = await basecamp.get(`/payment-notice-summaries/${paymentNoticeSummary.paymentNoticeSummaryId}`, {
        headers: { Accept: 'text/xml' },
        responseType: 'blob',
    });

    if (data.size === 0) {
        showErrorMessage({ message: 'Unknown error while downloading payment-order xml, please try again later' });
        return;
    }

    const fileName = `FM_${paymentNoticeSummaryEstate.condoUuid ?? paymentNoticeSummaryEstate.estateId}_${dayjs().format('DD.MM.YYYY')}.xml`;
    const url = window.URL.createObjectURL(new Blob([data]));
    openDownloadLink(url, fileName);
};
