/*Temporary to overcome nasty message in console for unlicensed ag-Grid*/
export const consoleError = () => {
    const original = console.error;

    console.error = (...args) => {
        if (args[0].includes && (args[0].includes('******************************') || args[0].toLowerCase().includes('* '))) {
            return;
        }
        original.apply(console, args);
    };
};
