import { basecamp } from '@/api/api-client';
import {
    type ICommonTableFetchParams,
    type ICreateSellingHandoverDateParams,
    type IEligibleEstatesWithDocumentsQuery,
    type ISellingEstate,
    type ISellingEstatesDataQuery,
    SellingProcess,
} from '@condo/domain';
import { omit } from 'lodash-es';

export const findSellingEstates = async (params?: ICommonTableFetchParams): Promise<{ estates: ISellingEstate[]; total: number }> =>
    basecamp.get('/selling/estates', { params }).then(response => {
        const { estates, total } = response.data;
        const estatesWithSellingProcess = estates.map(estate => ({
            ...estate,
            selling: estate.selling ? new SellingProcess({ ...estate.selling, events: estate.calendarEvents ?? estate.selling.events }) : undefined,
        }));
        return { estates: estatesWithSellingProcess, total };
    });

export const findSellingEstatesData = async (params?: ISellingEstatesDataQuery): Promise<{ estates: ISellingEstate[]; total: number }> =>
    basecamp.get('/selling/data', { params }).then(response => response.data);

export const findEligibleEstatesWithDocuments = async (params?: IEligibleEstatesWithDocumentsQuery): Promise<{ estates: ISellingEstate[]; total: number }> =>
    basecamp.get('/selling/estates/elegible/documents', { params }).then(response => {
        const { estates, total } = response.data;
        const estatesWithSellingProcess = estates.map(estate => ({
            ...estate,
            selling: estate.selling ? new SellingProcess({ ...estate.selling, events: estate.calendarEvents ?? estate.selling.events }) : undefined,
        }));
        return { estates: estatesWithSellingProcess, total };
    });

export const findSellingDataChecklistOfEstates = async (params?: IEligibleEstatesWithDocumentsQuery): Promise<{ estates: ISellingEstate[]; total: number }> =>
    basecamp.get('/selling/estates/data-checklist', { params }).then(({ data }) => data);

export const createSellingHandoverDate = async (params: ICreateSellingHandoverDateParams): Promise<void> =>
    basecamp.post(`/estates/${params.estateId}/selling/handover-date`, omit(params, 'estateId'));
