import type { ICreateJobParams } from '@/store/modules/jobs.store';
import type { IFindJobsParams, IJobDTO, IJobExecutionOpts } from '@condo/domain';
import { basecamp } from './api-client';

export const cancelJob = async (jobId: string): Promise<void> => basecamp.delete(`/jobs/${jobId}`);

export const findScheduledJobs = async (): Promise<void> => basecamp.get('/scheduled-jobs').then(response => response.data);

export const findUserJobs = async (params?: IFindJobsParams): Promise<IJobDTO[]> => basecamp.get('/user/jobs', { params }).then(response => response.data);

export const findJobs = async (params?: IFindJobsParams): Promise<IJobDTO[]> => basecamp.get('/jobs', { params }).then(response => response.data);

export const createJob = async (jobName: string, params: Record<string, any>, meta: Record<string, any>, opts?: IJobExecutionOpts): Promise<IJobDTO> =>
    basecamp.post('/jobs', { jobName, meta, params, opts }).then(response => response.data);

export const createDocumentJob = async (params: ICreateJobParams): Promise<IJobDTO> =>
    basecamp.post('/documents/jobs', params).then(response => response.data.job);

export const clearJobs = async (userId?: string): Promise<void> => basecamp.delete('/user/jobs', { params: { userId } });
