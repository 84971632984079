import { basecamp, propx } from '@/api/api-client';
import {
    type ExportMonthlyInvestorShareResult,
    type FindPropertyManagementTasksOverviewResult,
    type IEstateCredential,
    type IEstateMeter,
    type IEstatesResponse,
    type IFetchPropertyManagementTasksOverview,
    type IFetchRentTenancyOverview,
    type IFetchTransactionRuleOpts,
    type IGenerateRentArrearNoticeParams,
    type IInvestorInfo,
    type IMeterReading,
    type IMonthlyInvestorShare,
    type IMonthlyInvestorShareFilterOptions,
    type IMonthlyRentGuarantee,
    type IPaginatedResponse,
    type IPropertyManagementReqQuery,
    type IRent,
    type IRentBalanceAdjustment,
    type IRentTenancy,
    type IRentTenancyDTO,
    InvestorInfo,
    RentTenancy,
    type StringDate,
} from '@condo/domain';
import type {
    CreateInvestorInfoMutationRequest,
    FindInvestorInfosQueryParams,
    FindMonthlyRentGuaranteesQueryParams,
    FindMonthlyRentGuaranteesQueryResponse,
    GetEstateRentTenanciesQueryParams,
    GetInvestorInfoQueryParams,
    GetMinMaxDateOfMonthlyInvestorShareQueryResponse,
    GetRentTenancyQueryParams,
    PortalName,
    UpdateInvestorInfoMutationRequest,
    UpdateInvestorInfoQueryParams,
    UpdateMonthlyInvestorShareMutationRequest,
    UpdateMonthlyInvestorShareMutationResponse,
    UpdateMonthlyInvestorShareOperationsMutationRequest,
} from '@condo/propx/sdk';

export type IFetchRentTenanciesOverviewParams = IFetchRentTenancyOverview['findOpts'] & IFetchRentTenancyOverview['queryOpts'];
export type IFetchPropertyManagementTasksOverviewParams = IFetchPropertyManagementTasksOverview['findOpts'] &
    IFetchPropertyManagementTasksOverview['queryOpts'];

export const findPropertyManagementEstates = async (params?: IPropertyManagementReqQuery): Promise<IEstatesResponse> =>
    basecamp.get('/estates/property-management', { params }).then(response => response.data);

export const fetchEstateMeters = async (estateId: number): Promise<IEstateMeter[]> =>
    basecamp.get(`/estates/${estateId}/estate-meters`).then(r => r.data.estateMeters);

export const saveEstateMeter = async (meter: Partial<IEstateMeter>): Promise<IEstateMeter> => {
    const { estateId } = meter;
    return basecamp.post(`/estates/${estateId}/estate-meters`, meter).then(r => r.data.meter);
};

export const saveEstateReading = async (estateMeterId: number, reading: IMeterReading): Promise<IMeterReading> =>
    basecamp.post(`/estate-meters/${estateMeterId}/readings`, reading).then(r => r.data.meter);

export const deleteMeter = async (estateMeterId: number): Promise<void> => basecamp.delete(`/estate-meters/${estateMeterId}`);

export const deleteMeterReading = async (estateMeterId: number, readingId: number): Promise<void> =>
    basecamp.delete(`/estate-meters/${estateMeterId}/readings/${readingId}`).then(r => r.data);

// =================== investor infos ===================

export const saveInvestorInfo = async (
    investorInfoDto: CreateInvestorInfoMutationRequest | UpdateInvestorInfoMutationRequest,
    opts?: UpdateInvestorInfoQueryParams,
): Promise<InvestorInfo> => {
    if (investorInfoDto.investorInfoId) {
        const data = await propx.updateInvestorInfo({ investorInfoId: investorInfoDto.investorInfoId }, investorInfoDto, opts);
        return new InvestorInfo(data.investorInfo);
    }
    const data = await propx.createInvestorInfo(investorInfoDto as CreateInvestorInfoMutationRequest);
    return new InvestorInfo(data.investorInfo);
};

export const findInvestorInfos = async (query?: { estateId?: number }, opts?: Omit<FindInvestorInfosQueryParams, 'estateId'>): Promise<InvestorInfo[]> =>
    propx.findInvestorInfos({ ...query, ...opts }).then(({ investorInfos }) => investorInfos.map(iinfo => new InvestorInfo(iinfo)));

export const getInvestorInfo = async (investorInfoId: number, opts?: GetInvestorInfoQueryParams): Promise<InvestorInfo> => {
    const data = await propx.getInvestorInfo({ investorInfoId }, opts);
    return new InvestorInfo(data.investorInfo);
};

// =================== rent tenancy ===================

export const saveTenancy = async (
    rentTenancy: IRentTenancyDTO & IFetchTransactionRuleOpts,
    investorInfo?: Partial<IInvestorInfo>,
    rent?: Partial<IRent>,
): Promise<RentTenancy> => {
    if (rentTenancy.rentTenancyId) {
        const data = await propx.updateRentTenancy({ rentTenancyId: rentTenancy.rentTenancyId }, rentTenancy);
        return new RentTenancy(data.rentTenancy);
    }
    const data = await propx.saveRentTenancy({ rentTenancy, investorInfo, rent });
    return new RentTenancy(data.rentTenancy);
};

export const fetchRentTenancy = async (rentTenancyId: number, params?: GetRentTenancyQueryParams): Promise<RentTenancy> => {
    const data = await propx.getRentTenancy({ rentTenancyId }, params);
    return new RentTenancy(data as IRentTenancy);
};

export const fetchRentTenancyOverview = async (
    params: IFetchRentTenanciesOverviewParams,
): Promise<{
    rentTenancies: RentTenancy[];
    count: number;
}> => {
    const { data } = await basecamp.get<{ rentTenancies: IRentTenancy[]; count: number }>(`/rent-tenancies`, { params });
    return { rentTenancies: data.rentTenancies.map((rt: IRentTenancy) => new RentTenancy(rt)), count: data.count };
};

export const fetchRentTenanciesOfEstate = async (estateId: number, params: GetEstateRentTenanciesQueryParams): Promise<RentTenancy[]> => {
    const data = await propx.getEstateRentTenancies({ estateId }, params);
    return (data.rentTenancies.map((rt: IRentTenancy) => new RentTenancy(rt)) as RentTenancy[]).sort((a, b) => b.rentTenancyId - a.rentTenancyId);
};

export const recalculateRentBalance = async (rentTenancyId: number): Promise<RentTenancy> => {
    const data = await propx.recalculateRentBalance({ rentTenancyId });
    return new RentTenancy(data.rentTenancy as IRentTenancy);
};

export const fetchActiveRentTenanciesOfManyEstate = async (estateIds: number[]): Promise<RentTenancy[]> => {
    const data = await propx.getManyEstateActiveRentTenancies({ estateIds });
    return data.rentTenancies.map((rt: IRentTenancy) => new RentTenancy(rt));
};

// =================== rent ===================

export const saveRent = async (rent: IRent): Promise<IRent> => {
    if (rent.rentId) {
        const data = await propx.updateRent({ rentTenancyId: rent.rentTenancyId, rentId: rent.rentId }, rent);
        return data.rent;
    }
    const data = await propx.createRent({ rentTenancyId: rent.rentTenancyId }, rent);
    return data.rent;
};

export const updateRentBalanceAdjustments = async (rentId: number, balanceAdjustments: IRentBalanceAdjustment[]): Promise<void> => {
    await propx.updateRentBalanceAdjustments({ rentId }, { balanceAdjustments });
};

export const getMonthlyInvestorShare = async (
    query?: IMonthlyInvestorShareFilterOptions,
): Promise<IPaginatedResponse<IMonthlyInvestorShare, 'investorShares'>> => {
    return basecamp.get('/investor-share', { params: query }).then(({ data }) => {
        return {
            investorShares: data.investorShares.map(iinfo => {
                return { ...iinfo, investorInfo: new InvestorInfo(iinfo.investorInfo) };
            }),
            pagination: data.pagination,
        };
    });
};

export const getMonthlyInvestorShareIds = async (query?: IMonthlyInvestorShareFilterOptions): Promise<any[]> => {
    return basecamp.get('/investor-share/ids', { params: query }).then(({ data }) => {
        return data;
    });
};

export const fetchMonthlyRentGuarantees = async (params?: FindMonthlyRentGuaranteesQueryParams): Promise<FindMonthlyRentGuaranteesQueryResponse> =>
    propx.findMonthlyRentGuarantees(params);

export const updateMonthlyRentGuarantee = async (monthlyRentGuaranteeId: number, data: Partial<IMonthlyRentGuarantee>): Promise<IMonthlyRentGuarantee> =>
    propx.updateMonthlyRentGuarantee({ monthlyRentGuaranteeId }, data).then(({ monthlyRentGuarantee }) => monthlyRentGuarantee);

export const getDateRangeOfMonthlyInvestorShare = async (): Promise<GetMinMaxDateOfMonthlyInvestorShareQueryResponse> => {
    return propx.getMinMaxDateOfMonthlyInvestorShare();
};

export const getMonthlyInvestorSharePaymentFile = async (query?: { month: StringDate }): Promise<ExportMonthlyInvestorShareResult> => {
    const { data } = await basecamp.get('/investor-share/payment-file', {
        params: query,
    });

    return data;
};

export const updateMonthlyInvestorShare = async (
    monthlyInvestorShareId: number,
    monthlyInvestorShare?: UpdateMonthlyInvestorShareMutationRequest['data'],
    operationRows?: UpdateMonthlyInvestorShareMutationRequest['operationRows'],
): Promise<UpdateMonthlyInvestorShareMutationResponse['monthlyInvestorShare']> =>
    propx
        .updateMonthlyInvestorShare(
            { monthlyInvestorShareId },
            {
                operationRows,
                data: monthlyInvestorShare,
            },
        )
        .then(data => data.monthlyInvestorShare);

export const updateMonthlyInvestorShareOperations = async (monthlyInvestorShareId: number, data: UpdateMonthlyInvestorShareOperationsMutationRequest) =>
    propx.updateMonthlyInvestorShareOperations({ monthlyInvestorShareId }, data);

export const fetchPropertyManagementTasksOverview = async (
    params: IFetchPropertyManagementTasksOverviewParams,
): Promise<FindPropertyManagementTasksOverviewResult> =>
    basecamp.get<FindPropertyManagementTasksOverviewResult>(`/property-management-tasks`, { params }).then(({ data }) => data);

export const generateRentTenancyReport = async (rentTenancyId: number): Promise<string> =>
    basecamp.post<{ sheetLink: string }>(`/rent-tenancies/${rentTenancyId}/report/generate`).then(({ data }) => data.sheetLink);

export const generateRentGuarantee = async (month: StringDate): Promise<string> =>
    propx.rentGuaranteeBatchGenerate({ month }).then(({ driveFolderId }) => driveFolderId);

export const generateRentArrearNotices = async (rentArrearNotices: IGenerateRentArrearNoticeParams[]): Promise<string> =>
    propx.generateArrearNotices({ rentArrearNotices }).then(({ driveFolderId }) => driveFolderId);

export const deleteRentArrearNotice = async (rentArrearNoticeId: number): Promise<void> => propx.deleteArrearNotice({ rentArrearNoticeId });

export const sendContactInvitationRequest = async ({
    contactId,
    portalName,
}: {
    contactId: number;
    portalName: PortalName;
}): Promise<void> => {
    await propx.sendContactInvitation({ contactId }, { portalName });
};

export const fetchEstateCredentials = async (estateId: number): Promise<IEstateCredential[]> =>
    basecamp.get<{ estateCredentials: IEstateCredential[] }>(`/estates/${estateId}/credentials`).then(({ data }) => data.estateCredentials);

export const deleteEstateCredential = async (estateCredentialId: number): Promise<void> => {
    await basecamp.delete(`/estates/credentials/${estateCredentialId}`);
};

export const createEstateCredential = async (estateId: number, data: Partial<IEstateCredential>): Promise<IEstateCredential> =>
    basecamp
        .post<{
            estateCredential: IEstateCredential;
        }>(`/estates/${estateId}/credentials`, data)
        .then(({ data }) => data.estateCredential);

export const getEntitySetting = async (
    estateId: number,
): Promise<{
    [key: string]: string | boolean;
} | null> => {
    const { entitySetting } = await propx.getEntitySetting({ estateId });

    return entitySetting?.value ?? null;
};

export const saveFinancialModuleSetting = async (estateId: number, value: boolean): Promise<void> => {
    await propx.upsertEntitySetting({ estateId, value: { disableFinancialModule: value } });
};

export const restoreTenancyLedger = async (rentTenancyId: number): Promise<void> => basecamp.put(`/rent-tenancies/${rentTenancyId}/restore-ledger`, {});
