import { basecamp, propx } from '@/api/api-client';
import {
    type ICommonTableFetchParams,
    type IEstate,
    type IInvestment,
    type IRentingDetails,
    type IRentingEstate,
    InvestmentCase,
    InvestorInfo,
    RentingProcess,
} from '@condo/domain';
import type {
    FindRenalContractsQueryParams,
    FindRenalContractsQueryResponse,
    UpsertRentalContractMutationRequest,
    UpsertRentalContractMutationResponse,
} from '@condo/propx/sdk';

export const findRentingEstates = async (params?: ICommonTableFetchParams): Promise<{ estates: IRentingEstate[]; total: number }> =>
    basecamp.get('/renting/estates', { params }).then(response => {
        const { estates, total } = response.data;
        const estatesWithRentingProcess = estates.map(estate => ({
            ...estate,
            renting: estate.renting ? new RentingProcess({ ...estate.renting, events: estate.calendarEvents }) : undefined,
            executedInvestmentCase: estate.executedInvestmentCase
                ? new InvestmentCase({ ...(estate.executedInvestmentCase as IInvestment), estate })
                : undefined,
            investorInfos: estate.investorInfos ? estate.investorInfos.map(investorInfo => new InvestorInfo(investorInfo)) : [],
        }));
        return { estates: estatesWithRentingProcess, total };
    });

/*Renting*/
export const getRentingDetailsOfEstate = async (estate: IEstate): Promise<IRentingDetails[]> => {
    const { rentingDetails } = await propx.getRentingDetailsOfEstate({ estateId: estate.estateId });
    return rentingDetails.map(rd => {
        return {
            ...rd,
            executedInvestmentCase: rd.executedInvestmentCase ? new InvestmentCase({ ...(rd.executedInvestmentCase as IInvestment), estate }) : undefined,
        };
    }) as IRentingDetails[];
};

export const fetchEstateRentalContracts = async (estateId: number): Promise<FindRenalContractsQueryResponse['rentalContracts']> => {
    const { rentalContracts } = await fetchRentalContracts({ query: { estateId } });
    return rentalContracts;
};

export const fetchRentalContracts = async (params: FindRenalContractsQueryParams): Promise<FindRenalContractsQueryResponse> => propx.findRenalContracts(params);

export const createOrUpdateRentalContract = async (
    rentalContract: UpsertRentalContractMutationRequest['rentalContract'],
): Promise<UpsertRentalContractMutationResponse> => propx.upsertRentalContract({ rentalContract });
