import { type IBaseRentableUnit, type IRentableUnit, RentableUnit } from '@condo/domain';
import { propx } from './api-client';

export const getRentableUnits = async (estateId: number): Promise<RentableUnit[]> =>
    propx
        .findRentableUnits({ estateId, externalRelations: ['estateElement', 'parkingSpace'] })
        .then(data => data.rentableUnits.map(rentableUnit => new RentableUnit(rentableUnit)));

export const getActiveRentableUnits = async (estateId: number): Promise<RentableUnit[]> =>
    propx
        .findRentableUnits({ estateId, onlyActive: true, externalRelations: ['estateElement', 'parkingSpace'] })
        .then(data => data.rentableUnits.map(rentableUnit => new RentableUnit(rentableUnit)));

export const updateRentableUnit = async (rentableUnitId: number, rentableUnit: IBaseRentableUnit): Promise<void> => {
    await propx.updateRentableUnit({ rentableUnit: { ...rentableUnit, rentableUnitId } });
};

export const getRoomRentableUnit = async (estateElementId: number): Promise<IRentableUnit | null> => propx.getRentableUnit({ estateElementId });

export const getParkingRentableUnit = async (parkingSpaceId: number): Promise<IRentableUnit | null> => propx.getRentableUnit({ parkingSpaceId });

export const createRoomRentableUnit = async (estateElementId: number, estateId: number): Promise<IRentableUnit> =>
    propx.createRoomRentableUnit({ rentableUnit: { estateElementId, estateId } });

export const deleteRoomRentableUnit = async (estateElementId: number): Promise<void> => {
    await propx.deleteRoomRentableUnit({ estateElementId });
};
