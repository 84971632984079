import type {
    EstateExpandOpts,
    FormSchema,
    IBaseDDQuestion,
    IDDAnswer,
    IDDAnswerUpsertParams,
    IDDQuestion,
    IEstate,
    ILocation,
    ILocationDTO,
    IMFH,
    IMFHBuilding,
    IMFHPortfolio,
    IMediaFile,
    IMfhFilter,
    IPaginationFields,
    IUpdateRentRollDDParams,
    LocationExpandOpts,
    MFHBuildingExpandOpts,
    MFHExpandOpts,
    MFHPortfolioExpandOpts,
} from '@condo/domain';
import { basecamp } from '../api-client';

export const fetchMfhList = async (
    filter: IMfhFilter,
    expandOptions?: MFHExpandOpts,
): Promise<{
    mfhs: IMFH[];
    total: number;
}> => basecamp.get('/mfh', { params: { ...filter, expand: expandOptions } }).then(response => response.data);

export const fetchMfhSourcingList = async (
    filter: IMfhFilter,
): Promise<{
    mfhs: IMFH[];
    total: number;
}> => basecamp.get('/mfh-sourcing-list', { params: filter }).then(response => response.data);

export const fetchOneMfh = async (mfhId: number, expandOptions?: MFHExpandOpts): Promise<IMFH> =>
    basecamp
        .post(`/mfh/${mfhId}`, {
            expand: expandOptions,
        })
        .then(response => response.data);

export const getMfhEstates = async (mfhId: number, expandOptions: EstateExpandOpts): Promise<IEstate[]> =>
    basecamp
        .get(`/mfh/${mfhId}/estates`, {
            params: {
                expand: expandOptions,
            },
        })
        .then(response => response.data);

export const getMfhLocations = async (mfhId: number, expandOptions: LocationExpandOpts): Promise<ILocation[]> =>
    basecamp
        .get(`/mfh/${mfhId}/locations`, {
            params: {
                expand: expandOptions,
            },
        })
        .then(response => response.data);

export const createMFH = async (body: { mfh: Partial<IMFH>; linkableMedia?: IMediaFile[] }): Promise<IMFH> =>
    basecamp.post('/mfh', body).then(response => response.data);

export const createMFHEstate = async (mfhBuildingId: number, locationId: number): Promise<IEstate> =>
    basecamp.post(`/mfh/buildings/${mfhBuildingId}/estates`, { locationId }).then(response => response.data.estate);

export const updateMFH = async (mfhID: number, body: Partial<IMFH>): Promise<IMFH> => basecamp.patch(`/mfh/${mfhID}`, body).then(response => response.data);

export const upsertMFHBuildingLocation = async (mfhBuildingId: number, location: ILocationDTO): Promise<void> =>
    basecamp.put(`/mfh/buildings/${mfhBuildingId}/locations`, { location });

export const upsertMFHBuilding = async (mfhId: number, mfhBuilding: Partial<IMFHBuilding>): Promise<IMFHBuilding> =>
    basecamp.put(`/mfh/${mfhId}/buildings/`, { mfhBuilding }).then(res => res.data.mfhBuilding);

export const deleteMFHBuilding = async (mfhId: number, mfhBuildingId: number): Promise<IMFHBuilding> =>
    basecamp.delete(`/mfh/${mfhId}/buildings/${mfhBuildingId}`);

export const deleteMFHBuildingLocation = async (mfhBuildingId: number, locationId: number): Promise<void> =>
    basecamp.delete(`/mfh/buildings/${mfhBuildingId}/locations/${locationId}`);

export const getMfhDDQuestions = async <const TSchema extends Array<FormSchema> = Array<FormSchema>>(
    mfhId: number,
    type: TSchema,
): Promise<IDDQuestion<TSchema extends Array<infer U> ? U : never>[]> =>
    basecamp.get(`/mfh/${mfhId}/dd/questions`, { params: { type } }).then(response => response.data.questions);

export const upsertMfhDDQuestion = async <const TSchema extends Array<FormSchema> = Array<FormSchema>>(
    question: IBaseDDQuestion,
): Promise<IDDQuestion<TSchema extends Array<infer U> ? U : never>[]> =>
    basecamp.post(`/mfh/${question.mfhId}/dd/questions`, question).then(response => response.data.questions);

export const deleteMfhDDQuestion = async <const TSchema extends Array<FormSchema> = Array<FormSchema>>(
    mfhId: number,
    questionId: number,
): Promise<IDDQuestion<TSchema extends Array<infer U> ? U : never>[]> =>
    basecamp.delete(`/mfh/${mfhId}/dd/questions/${questionId}`).then(response => response.data.questions);

export const updateMfhDDAnswer = async (mfhId: number, data: IDDAnswerUpsertParams): Promise<IDDAnswer> =>
    basecamp.put(`/mfh/${mfhId}/dd/answers`, data).then(response => response.data.answer);

export const getMfhAttributesFromInfas = async (mfhId: number): Promise<void> => basecamp.get(`/mfh/${mfhId}/infas-attributes`).then(response => response.data);

export const updateRentRollDD = async (estateId: number, body: Pick<IUpdateRentRollDDParams, 'attributes' | 'rentalContract'>): Promise<void> =>
    basecamp.patch(`/mfh/estates/${estateId}/rent-roll-dd`, body).then(response => response.data);

export const fetchMfhPortfolioList = async (
    query: Partial<IMFHPortfolio>,
    expand: MFHPortfolioExpandOpts,
    pagination?: IPaginationFields,
): Promise<{
    mfhPortfolios: IMFHPortfolio[];
    total: number;
}> => basecamp.get('/mfh-portfolios', { params: { query, expand, pagination } }).then(response => response.data);

export const fetchOneMfhPortfolio = async (mfhPortfolioId: number, expandOptions?: MFHPortfolioExpandOpts): Promise<IMFHPortfolio> =>
    basecamp
        .get(`/mfh-portfolio/${mfhPortfolioId}`, {
            params: {
                expand: expandOptions,
            },
        })
        .then(response => response.data);

export const createMFHPortfolio = async (payload: { name: string; mfhIds: number[] }): Promise<IMFHPortfolio> =>
    basecamp.post('/mfh-portfolio', payload).then(response => response.data);

export const updateMFHPortfolio = async (
    mfhPortfolioId: number,
    payload: {
        name?: string;
        mfhIds?: number[];
    },
): Promise<IMFHPortfolio> => basecamp.patch(`/mfh-portfolio/${mfhPortfolioId}`, payload).then(response => response.data);

export const fetchMfhBuilding = async (mfhBuildingId: number, expand: MFHBuildingExpandOpts): Promise<IMFHBuilding> =>
    basecamp.get(`/mfh/buildings/${mfhBuildingId}`, { params: { expand } }).then(response => response.data.mfhBuilding);

export const fetchMfhBuildings = async (mfhId: number, expand: MFHBuildingExpandOpts): Promise<IMFHBuilding[]> =>
    basecamp.get(`/mfh/${mfhId}/buildings`, { params: { expand } }).then(response => response.data.mfhBuildings);
