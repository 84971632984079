import { ErrorCode } from '@condo/domain';
import { CondoToast } from '@condo/ui';
import { v4 as uuid } from 'uuid';
import { useToast } from 'vue-toastification';
import { ToastType } from './helpers';

const toast = useToast();

export enum MessageType {
    Warning = 'is-warning',
    Danger = 'is-danger',
    Success = 'is-success',
    GreenDark = 'is-green-dark',
    SuccessDark = 'is-success-dark',
}

export const showSnackbar = (message: string, type: string | MessageType = 'is-success', timeout?: number, isPermissionError?: boolean) => {
    const finalType = type === MessageType.Danger ? ToastType.ERROR : type === MessageType.GreenDark ? ToastType.SUCCESS : ToastType.INFO;
    showAlertDialog(message, { timeout: timeout ?? false, isPermissionError }, finalType);
};

const processAxiosErrorResponse = (message: string, axiosResponse: Record<string, any>): { message: string; isPermissionError?: boolean } => {
    const errorCode = axiosResponse?.data?.code ?? axiosResponse?.data?.data?.code;
    switch (errorCode) {
        case ErrorCode.Is24Validation: {
            const parsedErrors = axiosResponse?.data?.context?.parsedErrors?.map(e => e.error?.field ?? e.error.error) ?? [];
            if (parsedErrors.length) {
                return { message: `IS24 returned a validation-error when processing your request. Wrong fields. ${parsedErrors.map(m => `'${m}'`).join(',')}` };
            }
            const innerErrorMessage = axiosResponse?.data?.context?.message ?? message;
            return { message: `IS24 returned a validation-error when processing your request: ${innerErrorMessage}` };
        }
        case ErrorCode.Unauthorized: {
            const missingPermissions = axiosResponse?.data?.context?.missingPermissions ?? [];
            if (missingPermissions.length) {
                return {
                    message: `You are not authorized to execute this action. Missing the permissions: ${missingPermissions.join(', ')}`,
                    isPermissionError: true,
                };
            }

            return { message: message, isPermissionError: true };
        }
        case ErrorCode.ConcurrentJobRunning: {
            return { message: 'Another job is running right now, you cannot start another one of the same kind' };
        }
        case ErrorCode.ExternalServiceFailed: {
            return { message: 'Calling external service failed, please try again later' };
        }
        case ErrorCode.DocumentGenerationFailed: {
            return { message: 'Document generation failed, please check jobs page for errors' };
        }

        default: {
            return { message: message };
        }
    }
};

export const showErrorMessage = (opts: { message: string; responseData?: Record<string, any>; streamId?: string; timeout?: number }) => {
    const timeout = opts.timeout ?? 8000;
    const reqIdAddon = opts.streamId ? ` <br /> <i style="font-size: 13px">[request-id ${opts.streamId}]</i>` : '';
    if (opts.responseData) {
        const { message: mappedErrorMessage, isPermissionError = false } = processAxiosErrorResponse(opts.message, opts.responseData);
        /**
         * Logic is following:
         *  - If code 412 PreCondition failed we show different type of pop-up
         *      - 412 means that error connected with some business rules checks (e.g. status change requires data, etc.)
         *      - We will show not message but modal window with ok button to allow read the message and confirm it
         *  - All other cases just show a snackbar with the error
         */
        const code = opts.responseData?.statusCode;
        if (code === 412) {
            return showAlertDialog(`${mappedErrorMessage}${reqIdAddon}`, { timeout: false, isPermissionError }, ToastType.ERROR);
        }

        return showSnackbar(`${mappedErrorMessage}${reqIdAddon}`, MessageType.Danger, timeout, isPermissionError);
    }
    return showSnackbar(`${opts.message}${reqIdAddon}`, MessageType.Danger, timeout);
};

export const showMessage = (message: string, type: string | MessageType = 'is-success', timeout = 8000, id?: string) => {
    const toastType = type === MessageType.Danger ? ToastType.ERROR : type === MessageType.GreenDark ? ToastType.SUCCESS : ToastType.INFO;

    return showAlertDialog(message, { timeout }, toastType, id);
};

export const showAlertDialog = (message: string, opts: Record<string, any> = {}, type: ToastType = ToastType.INFO, id?: string) => {
    const { timeout = 8000, isPermissionError = false } = opts;

    //TODO: Remove this HAXX introduced for hiding errors from MFH Investor (KO-6109) and Peakside Invoice Approval (KO-5855)
    const shouldBeSuppressed = ((window as any).userRoles as string[])?.some(role => ['MFH-Investor', 'Asset Partner Reviewer'].includes(role));
    const showToast = !shouldBeSuppressed || (shouldBeSuppressed && !isPermissionError);

    if (!showToast) {
        return;
    }

    return toast(
        {
            component: CondoToast,
            props: {
                message,
                type,
            },
        },
        { type, timeout, id: id ?? uuid() },
    );
};
